import React from "react"

import "./Background.scss"

const BackgroundStatic = ({imageType, imageSrc }) => {
  const width = typeof document !== 'undefined' && document.body.clientWidth
  return (
    <>
      {width > 767 ?
      //  (
      //   <video
      //     preload="auto"
      //     no-controls
      //     autoplay="true"
      //     loop="true"
      //     playsinline="true"
      //     muted="false"
      //   >
      //     <track kind="captions" />
      //     <source src={videoSrc} type={videoType} />
      //   </video>
      // ) : 
      (
        <picture>
          <source src={imageSrc} type={imageType} />
          <img src={imageSrc} alt="background" />
        </picture>
      ): 
      <picture>
      <div style={{width:'100%', height:'100%', background:'#000'}} alt="background" />
    </picture>
      }
    </>
  )
}

export default BackgroundStatic
