import React from "react"

import "./Form.scss"

const Form = ({ type, placeholder, required }) => {
  if (type === undefined) {
    type = "text"
  }
  return (
    <div className="input">
      <label
        className="input__label"
        htmlFor={placeholder.toLowerCase().replace(/[ /]/g, "-")}
      >
        {placeholder}
      </label>
      <input
        className="input__input"
        type={type}
        maxLength={256}
        name={placeholder}
        id={placeholder.toLowerCase().replace(/[ /]/g, "-")}
        placeholder={placeholder}
        required={required}
      />
    </div>
  )
}

export default Form
